.navbar-inverse {
  background-color: rgba(34, 34, 34, 0.8);

  .navbar-brand {
    color: #d4d4d4;
  }
}

.navbar-transparent {
  background-color: transparent;
}

.navbar-inverse .navbar-nav > li > a, .navbar-inverse .navbar-text {
  color: #d4d4d4;
}

.revolution {
  padding: 100px 0;
  color: rgba(#fff, .9);

  .section-title {
    color: #fff;
    margin-bottom: 30px;
  }

  p {
    width: 80%;
    margin: 0 auto 10px auto;
  }
}

.card-date {
  display: flex;

  > .date {
    //align-self: center;
    text-align: center;
    padding-left: 15px;
    padding-top: 15px;

    .month {
      font-size: 13px;
      text-transform: uppercase;
      background-color: #ff6250;
      color: #fff;
      padding: 2px 15px;
      border-radius: 6px 6px 0 0;
    }

    .day {
      font-size: 35px;
      text-align: center;
      background-color: rgb(238, 238, 238);
      border-radius: 0 0 6px 6px;
    }
  }

  .content {

  }
}

.card-product .title {
  height: 70px;
}

.fast-cart {
  li {
    padding: 10px;
  }

  &__image {
    margin-right: 10px;
  }

  &__name {
    color: #333;
    font-size: 15px;
    min-height: 25px;
  }

  &__price {

  }

  &__summary {
    padding: 0 !important;

    a {
      margin: 10px 15px 0 15px !important;
      font-weight: 600 !important;
    }
  }

  &__info {
    padding: 10px 15px;
    background-color: #4e4e4e;
    color: rgba(255, 255, 255, 1);
    font-size: 17px;
    font-weight: bold;
  }

  .empty-basket {
    margin-bottom: 0;
  }
}

.cart-buttons {
  padding-bottom: 20px;
  display: flex;
  align-items: center;

  &__item:last-child {
    text-align: right;
  }
}

.checkoutRow {
  display: flex;
}

.checkoutCol {
  border-right: 1px solid #F1EAE2;

  &:last-child {
    border-right: none;
  }

  > .checkoutCol__title {
    margin-top: 0;
  }

  &__table {
    margin-bottom: 0;
  }

  &__table tr:first-child {
    background-color: #F3F2EE;
  }

  &__table--cleared td {
    border-top: none !important;
  }

  &__table--total {
    font-size: 16px;
    font-weight: 600;
  }

  &__table__size {
    width: 100px;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.form-body--small {
  .form-group {
    margin-bottom: 7px;

    label {
      font-weight: 300;
      margin-bottom: 1px;
    }

    .form-control {
      padding: 5px 10px;
      height: 30px;
    }
  }

  .bootstrap-select {
    width: 100% !important;
  }
}

.form-group {
  .form-control.error {
    background-color: #ffc0a4;
    color: #eb5e28;
    border-color: #eb5e28;
  }

  label.error {
    color: #eb5e28;
    font-style: italic;
    font-size: 90%;
  }
}

.footer-big hr {
  margin-top: 30px;
}

.fc .popover {
  z-index: 999;

  .popover-content {
    background-color: rgba(221, 221, 221, 0.8);
    border-radius: 0 0 10px 10px;
  }

  &.top > .arrow:after {
    border-top-color: rgba(221, 221, 221, 0.8);
  }
}

.fc-day-grid-container {
  overflow: visible !important;
}

.cookies-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  left: 0;
  margin: 0;
  padding: 20px 120px;
  color: black;
  z-index: 9999;
  display: none;
  border-top: 1px solid #DDDDDD;
}

.cookies-alert .close-button {
  margin-left: 10px;
}

@media (max-width: 600px) {

  .cookies-alert {
    padding: 15px 30px;
  }

  .cookies-alert .close-button {
    margin-top: 10px;
    display: block;
  }

}